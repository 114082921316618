import { init, track as trackEvent } from "@amplitude/analytics-browser";

import amplitudeConfig from "~/config/amplitude";
import appConfig from "~/config/app";
import { usePlanturaStore } from "~/stores/plantura";

import { Event } from "./types";

type AmplitudeClientBrowserParams = {
  apiKey: string;
  environment: "production" | "development";
}

class AmplitudeClientBrowser {
  constructor(private clientBrowserPrams: AmplitudeClientBrowserParams) {
    init(this.clientBrowserPrams.apiKey, { defaultTracking: false, serverZone: "EU" });
  }

  track(event: Event) {
    if (this.clientBrowserPrams.environment !== "development") {
      return;
    }

    const { name, payload } = event.toJson();
    const client = usePlanturaStore.getState().getClient();

    trackEvent(name, {
      ...payload,
      source: client,
    });
  }
}

class AmplitudeClientMock {
  track(_event: Event) {
    console.error("Amplitude: Client only for browser environment");
  }
}

const createClient = () => {
  return globalThis.window
    ? new AmplitudeClientBrowser({ apiKey: amplitudeConfig.publicApiKey, environment: appConfig.publicAppEnv as AmplitudeClientBrowserParams['environment'] })
    : new AmplitudeClientMock();
};

const analytics = createClient();

export { analytics };
